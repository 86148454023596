<template>
  <a-row class="combination">
    <a-col :span="12" class="combination-item">
      <div class="combination-item-content add" @click="handleBindCombination">
        <span><my-icon type="iconxinzeng"></my-icon> 开通套餐</span>
      </div>
    </a-col>
    <a-col :span="12" v-for="(combination, key) in combinations" class="combination-item" :key="key">
      <div class="combination-item-content">
       <a-row style="margin-bottom: 8px;">
         <a-col span="19">
             <p class="name">
               <span class="label">套餐名称:</span>
               <span class="value">{{combination.combinationName}}</span>
               <span>有效期:</span>
               <span>{{new Date(combination.startAt*1000).Format('yyyy-MM-dd')}} 至 {{new Date(combination.endAt*1000).Format('yyyy-MM-dd')}}</span>
             </p>
             <p>
               <span class="label">本月用量 / 剩余:</span>
               <span>{{combination.hasUseFreeCups}} / {{combination.freeCups - combination.hasUseFreeCups}}</span>
             </p>
             <p><span class="label">套餐免费杯量:</span> <span>{{combination.freeCups}}</span></p>
         </a-col>
         <a-col span="5" class="right">
           <p @click="combination.allStaff = !combination.allStaff"> <a-switch size="small" v-model="combination.allStaff"></a-switch > 全员可用</p>
             <p class="unbind" ><i
               class="iconfont iconjiebang"></i> 关闭套餐</p>
         </a-col>
       </a-row>
        <div class="combination-btn">
          <div @click="showCombinationExtend(combination, key)">续约</div>
          <div @click="showBindDevice(combination)">替换</div>
          <div @click="showUseCondition(combination)">使用状况</div>
          <div @click="showHasBindDevice(combination.combinationUuid)">员工列表</div>
        </div>
      </div>
    </a-col>

  </a-row>
</template>

<script>
  export default {
    name: 'combination',
    components: {},
    mounted: function () {

    },
    data: function () {
      return {
        combinations: [
          {
            combinationName: '黑咖600',
            startAt: 1605957539,
            endAt: 1608957539,
            hasUseFreeCups: 150,
            freeCups: 200,
            allStaff: true
          }
        ],
        combinationUuids: [],
        bindCombinationVisible: false
      }
    },
    methods: {
      handleBindCombination() {
        this.combinationUuids = []
        this.combinations.forEach(item => {
          this.combinationUuids.push(item.combinationUuid)
        })
        this.bindCombinationVisible = true
      },

      showCombinationExtend(combination, index) {
        this.combination.month = 1
        this.combination.index = index
        this.combination.companyUuid = this.company.uuid
        this.combination.combinationUuid = combination.combinationUuid
        this.dialogCombinationVisible = true
      },
      showUseCondition() {
        // companyCombinationCondition({
        //   companyUuid: this.company.uuid,
        //   combinationUuid: combination.combinationUuid
        // }).then(res => {
        //   this.combinationDrinks = res.data || []
        //   this.showCombinationDrinkVisible = true
        // })
      },
      showBindDevice(combinator) {
        this.bind.uuid = combinator.combinationUuid
        this.bindVisible = true
        let vue = this

        this.$nextTick(function () {
          this.$refs.devices.loadDevices(vue.bind.name)
        })
      },
      showHasBindDevice() {
        // companyCombinationDevices({uuid: uuid}).then(res => {
        //   this.devices = res.data || []
        //   this.showDeviceVisible = true
        // })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .combination {
    .combination-item {
      padding: 0 0 $small-space $small-space;


      .add {
        cursor: pointer;
        color: $theme-color;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        text-align: center;
      }

      &-content {
        height: 130px;
        border-radius: $box-radius;
        border: 1px solid;
        padding: $middle-space;
        font-size: 13px;

        .right {
          text-align: right;

          .unbind {
            margin-top: $small-space;
            cursor: pointer;
            margin-left: $small-space;
            color: $theme-color;
          }

        }

        .label {
          // @include set_font_color($--font-02-color, $--font-color-dark);
          margin-right: 5px;
        }

        .value {
          font-weight: 500;
          display: inline-block;
          width: 160px;

        }

        .combination-btn {

          display: flex;

          div {
            flex: 1;
            text-align: center;
            font-weight: 500;
            margin-top: 8px;
            border-right: 2px solid $color-base;
          }

          div:last-of-type {
            border: none;
          }

          div:hover {
            color: $theme-color;
            cursor: pointer;
            border-color: $color-base;
          }
        }
      }
    }
  }
</style>
